import Img from 'gatsby-image'
import { rgba } from 'polished'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

const titleClass = 'headline--hero'

// const parallaxBgInitialScale = 1.12

const defaultStyles = {
  heightSm: '220px',
  heightMd: '300px',
  heightLg: '450px',
}

const homeStyles = {
  heightSm: '580px',
  heightMd: '700px',
  heightLg: '100vh',
  minHeightLg: '720px',
  maxHeightLg: '850px',
}

const ContainContent = styled.div`
  display: flex;
  align-items: ${(props) => (props.page === 'home' ? 'flex-end' : 'center')};
  padding: 32px 20px;
  margin-top: ${(props) => (props.page === 'artist-reunion' ? '24px' : null)};

  h1 {
    font-family: ${(props) => props.theme.typography.serifDisplay};
  }

  @media (min-width: ${(props) => props.theme.responsive.medium}) {
    margin-top: ${(props) =>
    props.page === 'home'
      ? null
      : props.page === 'artist-reunion'
        ? '0'
        : '30px'};
    padding: 65px 20px;
  }
`

const Wrapper = styled.header`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.page === 'artist-reunion'
      ? '#898989'
      : props.page === 'incentive-trip'
        ? null
        : props.page === 'virtual-meeting'
          ? '#ab1d27'
          : props.page === 'rewards'
            ? '#000000' : null};

  &,
  > .gatsby-image-wrapper {
    height: ${(props) =>
    props.page === 'home'
      ? homeStyles.heightSm
      : props.page === 'artist-reunion'
        ? '275px'
        : props.page === 'incentive-trip'
          ? '262px'
          : props.page === 'virtual-meeting'
            ? '304px'
            : defaultStyles.heightSm};
  }

  > .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
  }

  .${titleClass} {
    margin: 10px 0 0;
    font-size: ${(props) => (props.page === 'home' ? homeStyles.fsSm : null)};
    line-height: ${(props) => (props.page === 'home' ? homeStyles.lhSm : null)};
    color: ${(props) =>
    props.page === 'inventory'
      ? rgba(props.theme.colors.darkText, 0.6)
      : 'white'};
  }

  @media (min-width: ${(props) => props.theme.responsive.medium}) {
    margin-bottom: ${(props) => (props.page === 'home' ? '0' : null)};

    &,
    > .gatsby-image-wrapper {
      height: ${(props) =>
    props.page === 'home'
      ? homeStyles.heightMd
      : props.page === 'artist-reunion'
        ? '400px'
        : props.page === 'incentive-trip'
          ? '400px'
          : props.page === 'virtual-meeting'
            ? '360px'
            : defaultStyles.heightMd};
    }

    .${titleClass} {
      font-size: ${(props) => (props.page === 'home' ? homeStyles.fsMd : null)};
      line-height: ${(props) =>
    props.page === 'home' ? homeStyles.lhMd : null};
    }
  }

  @media (min-width: ${(props) => props.theme.responsive.large}) {
    &,
    > .gatsby-image-wrapper {
      height: ${(props) =>
    props.page === 'home'
      ? homeStyles.heightLg
      : props.page === 'artist-reunion'
        ? '600px'
        : props.page === 'incentive-trip'
          ? '600px'
          : props.page === 'virtual-meeting'
            ? '560px'
            : defaultStyles.heightLg};
      min-height: ${(props) =>
    props.page === 'home' ? homeStyles.minHeightLg : null};
      max-height: ${(props) =>
    props.page === 'home' ? homeStyles.maxHeightLg : null};
    }

    > .gatsby-image-wrapper {
      max-width: ${(props) =>
    props.page === 'artist-reunion'
      ? null
      : props.page === 'incentive-trip'
        ? null
        : props.page === 'virtual-meeting'
          ? null
          : null};
    }

    .${titleClass} {
      font-size: ${(props) => (props.page === 'home' ? homeStyles.fsLg : null)};
      line-height: ${(props) =>
    props.page === 'home' ? homeStyles.lhLg : null};
    }
  }

  @media screen and (max-width: ${(props) =>
    props.theme.responsive.underLarge}) {
    .menu-active & {
      z-index: 1;
    }
  }
`

const PageHeader = (props) => {
  const [windowSizeMobile, setWindowSizeMobile] = useState(true)

  const ref = useRef(null)

  const resizeEvents = ['resize', 'pageshow', 'load']

  // const domEvents = [
  //   'resize',
  //   'scroll',
  //   'touchstart',
  //   'touchmove',
  //   'touchend',
  //   'pageshow',
  //   'load',
  // ]

  // const handleEvent = () => {
  //   const scrollPos = window.scrollY
  //   const heroSection = ref && ref.current
  //   const heroImg =
  //     heroSection && heroSection.querySelector('.gatsby-image-wrapper')
  //   const heroHeight = heroSection && heroSection.offsetHeight
  //   const siteBanner = document.getElementById('siteBanner')
  //   if (props.page !== 'home') {
  //     if (heroSection) {
  //       if (siteBanner && scrollPos > siteBanner.offsetHeight) {
  //         heroImg.style.transform = `scale(${(parallaxBgInitialScale * 100 -
  //           (scrollPos - siteBanner.offsetHeight) /
  //             (heroHeight /
  //               ((parallaxBgInitialScale -
  //                 parallaxBgInitialScale / parallaxBgInitialScale) *
  //                 100))) /
  //           100})`
  //       } else if (!siteBanner) {
  //         heroImg.style.transform = `scale(${(parallaxBgInitialScale * 100 -
  //           scrollPos /
  //             (heroHeight /
  //               ((parallaxBgInitialScale -
  //                 parallaxBgInitialScale / parallaxBgInitialScale) *
  //                 100))) /
  //           100})`
  //       } else {
  //         heroImg.style.transform = `scale(${parallaxBgInitialScale}`
  //       }
  //     }
  //   }
  // }

  const handleResize = () => {
    const isMobile = window.matchMedia(
      `(max-width: ${theme.responsive.underLarge})`
    ).matches
    if (isMobile) {
      setWindowSizeMobile(true)
    } else {
      setWindowSizeMobile(false)
    }
  }

  useEffect(() => {
    // function setupEventsHandler() {
    //   domEvents.forEach(event =>
    //     window.addEventListener(event, handleEvent, {
    //       capture: true,
    //       passive: true,
    //     })
    //   )
    //   handleEvent()
    // }

    function setupResizeHandler () {
      resizeEvents.forEach((event) =>
        window.addEventListener(event, handleResize, {
          capture: true,
          passive: true,
        })
      )
      handleResize()
    }

    setupResizeHandler()
    // setupEventsHandler()

    return function cleanupEvents () {
      // domEvents.forEach(event => window.removeEventListener(event, handleEvent))
      resizeEvents.forEach((event) =>
        window.removeEventListener(event, handleResize)
      )
    }
  })

  return (
    <Wrapper ref={ref} {...props}>
      {props.imageMobile && windowSizeMobile ? (
        <Img fluid={props.imageMobile.fluid} critical loading="eager" />
      ) : props.imageDesktop ? (
        <Img
          fluid={props.imageDesktop.fluid}
          imgStyle={
            props.page === 'home' ? { objectPosition: 'center 20%' } : null
          }
          critical
          loading="eager"
        />
      ) : null}
      {props.title && (
        <ContainContent {...props} className="container">
          <h1 style={props.page === 'rewards' ? { fontStyle: 'normal' } : null} className={titleClass}>{props.title}</h1>
        </ContainContent>
      )}
      {props.children && (
        <ContainContent {...props} className="container">
          {props.children}
        </ContainContent>
      )}
    </Wrapper>
  )
}

export default PageHeader
