import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ReunionLogo from '../images/okc_seint_logo.png'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  text-align: center;
  color: white;

  h1 {
    font-family: ${props => props.theme.typography.sansSerif};
    font-size: 36px;
    line-height: 40px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
  }

  img {
    max-width: 350px;
    height: auto;
  }

  a {
    color: white;
    text-decoration: none;
  }

  svg {
    width: 270px;
    height: 72px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    img {
      max-width: 520px;
    }

    svg {
      width: 500px;
      height: 134px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    img {
      max-width: 720px;
    }

    svg {
      width: 727px;
      height: 195px;
    }
  }
`

const Detail = styled.p`
  text-transform: uppercase;
  letter-spacing: .02rem;
  font-size: 13px;
  margin-top: 4px;
  color: inherit;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 16px;
    margin-top: 14px;
    font-weight: 500;
    letter-spacing: .15rem
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 500;
    letter-spacing: .20rem;
  }
`

const ReunionHeader = props => (
  <Wrapper {...props}>
    <Link to="/events/artist-reunion/">
      <img className="" src={ReunionLogo} alt="Over the Rainbow" />
    </Link>
    {/*
    <Detail>San Antonio, TX - April 13-16, 2023</Detail>
    */}
  </Wrapper>
)

export default ReunionHeader
